import React from "react";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material";
import { customDark } from "../theme/variants";

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor:
    theme.palette.mode === "dark"
      ? alpha(theme.palette.background.default, 0.85)
      : alpha(theme.palette.background.default, 0.75),
  boxShadow:
    theme.palette.mode === "dark"
      ? `inset 0 0 100px ${theme.header.background}`
      : `inset 0 0 100px ${customDark[900]}`,

  "& .video": {
    width: "100%",
    height: "100%",
    display: "block",
    objectFit: "cover",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: -1,
  },
}));

const VideoBg = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: -2,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor: theme.header.background,
}));

export function BackgroundVideo({ mp4 }) {
  return (
    <Root>
      <video className="video" autoPlay muted loop preload="auto" playsInline>
        <source src={mp4} type="video/mp4" />
      </video>
      <VideoBg />
    </Root>
  );
}
