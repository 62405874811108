import React from "react";
import { styled } from "@mui/material/styles";

import AppBar from "./AppBar";
import Introduction from "./Introduction";
import JoinUs from "./JoinUs";
import Footer from "../../../components/Footer";

const FillContainer = styled("div")(({ theme }) => ({
  height: `calc(100vh - 470px - 357px - 60px)`,
  [theme.breakpoints.up("md")]: {
    minHeight: "200px",
  },
}));

function Presentation() {
  return (
    <>
      <AppBar />
      <Introduction />
      <JoinUs />
      <FillContainer />
      <Footer />
    </>
  );
}

export default Presentation;
