import React from "react";
import { Box } from "@mui/material";

const mapStyles = {
  height: "calc(100vh - 64px - 54px - 78px)",
  overflow: "none",
  position: "relative",
  width: "100%",
};

const Map = React.forwardRef(function Map({ children }, ref) {
  return (
    <Box component="section" sx={{ position: "relative" }}>
      <Box ref={ref} sx={mapStyles} id="public-map" />
      {children}
    </Box>
  );
});

export default Map;
