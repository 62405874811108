import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ThemeProvider, CssBaseline } from "@mui/material";
import createTheme from "./theme";
import Routes from "./routes/Routes";

const queryClient = new QueryClient();

function App() {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | North Plains GCD"
            defaultTitle="North Plains GCD Dashboard"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={createTheme()}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
