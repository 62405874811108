import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Monitor, Users, Map } from "react-feather";

import AuthGuard from "../components/AuthGuard";
import Landing from "../pages/presentation/Landing";

import PublicMap from "../pages/publicMap";
import { ROUTES } from "../constants";

const publicMapRoutes = {
  header: "Public Resources",
  id: "Interactive Map",
  icon: <Map />,
  path: ROUTES.HOME,
  name: "Interactive Map",
  component: PublicMap,
};

const accountRoutes = {
  id: "Account",
  icon: <Users />,
  children: [
    {
      path: ROUTES.USER_LOGOUT,
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
  guard: AuthGuard,
};

const landingRoutes = {
  id: "Landing Page",
  path: ROUTES.SPLASH,
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

export const dashboardLayoutRoutes = [accountRoutes];

export const dashboardMaxContentLayoutRoutes = [publicMapRoutes];

export const authLayoutRoutes = [];

export const presentationLayoutRoutes = [landingRoutes];

export const fullscreenMapRoutes = [];

export const sidebarRoutes = [publicMapRoutes];
