import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";
import Footer from "../components/Footer";
import {
  CssBaseline,
  IconButton,
  Paper as MuiPaper,
  Snackbar,
  Alert,
  Box,
  GlobalStyles,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { THEME } from "../constants";
import { useApp } from "../AppProvider";
import useBreakpoints from "../hooks/useBreakpoints";

const drawerWidth = THEME.MAIN_SIDEBAR_WIDTH;

const globalStyles = (theme) => ({
  html: { height: "100%" },
  body: {
    height: "100%",
    background: theme.palette.background?.default || "#f0f0f0",
  },
  "#root": { height: "100%" },
  ".MuiCardHeader-action .MuiIconButton-root": {
    padding: 4,
    width: 28,
    height: 28,
  },
  ".MuiMenu-paper": {
    border: `1px solid ${theme.palette?.divider || "#e0e0e0"}`,
  },
  ".success": {
    ".MuiSvgIcon-root, .MuiTypography-root, &.MuiButton-root": {
      color: theme.palette.success?.main || "#4caf50",
    },
    "&.MuiButton-root": {
      borderColor: theme.palette.success?.main || "#4caf50",
    },
    "&.MuiChip-root.filled": {
      color: theme.palette.primary?.contrastText || "#ffffff",
      backgroundColor: theme.palette.success?.main || "#4caf50",
    },
  },
  ".warning": {
    ".MuiSvgIcon-root, .MuiTypography-root, &.MuiButton-root": {
      color: theme.palette.warning?.main || "#ff9800",
    },
    "&.MuiButton-root": {
      borderColor: theme.palette.warning?.main || "#ff9800",
    },
    "&.MuiChip-root.filled": {
      color: theme.palette.primary?.contrastText || "#ffffff",
      backgroundColor: theme.palette.warning?.main || "#ff9800",
    },
  },
  ".info": {
    ".MuiSvgIcon-root, .MuiTypography-root, &.MuiButton-root": {
      color: theme.palette.info?.main || "#2196f3",
    },
    "&.MuiButton-root": {
      borderColor: theme.palette.info?.main || "#2196f3",
    },
    "&.MuiChip-root.filled": {
      color: theme.palette.primary?.contrastText || "#ffffff",
      backgroundColor: theme.palette.info?.main || "#2196f3",
    },
  },
  ".error": {
    ".MuiSvgIcon-root, .MuiTypography-root, &.MuiButton-root": {
      color: theme.palette.error?.main || "#f44336",
    },
    "&.MuiButton-root": {
      borderColor: theme.palette.error?.main || "#f44336",
    },
    "&.MuiChip-root.filled": {
      color: theme.palette.primary?.contrastText || "#ffffff",
      backgroundColor: theme.palette.error?.main || "#f44336",
    },
  },
  ".ellipsis": {
    boxSizing: "border-box",
    minWidth: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  "input:-webkit-autofill, textarea:-webkit-autofill, .MuiOutlinedInput-input:-webkit-autofill, select:-webkit-autofill":
    {
      WebkitBoxShadow: `0 0 0px 1000px ${
        theme.palette.mode === "dark"
          ? theme.header?.background || "#333"
          : theme.palette.background?.toolbar || "#ffffff"
      } inset !important`,
      WebkitTextFillColor:
        theme.palette.mode === "dark"
          ? theme.palette.primary?.contrastText || "#ffffff"
          : theme.palette.text?.secondary || "#000000",
    },
});

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
}));

const DrawerContainer = styled("div")(({ theme }) => ({
  position: "relative",
  background: "transparent",
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
    flexShrink: 0,
  },
  "&.isClosed": {
    width: 0,
  },
}));

const AppContent = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const MainContent = styled(Paper)(({ theme }) => ({
  flex: 1,
  background: theme.palette.background?.default || "#ffffff",
  "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
    flex: "none",
  },
  ".MuiPaper-root .MuiPaper-root": {
    boxShadow: "none",
  },
}));

const SidebarButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  zIndex: 1300,
  top: 86,
  left: `${drawerWidth - 12}px`,
  width: 24,
  height: 24,
  border: `1px solid ${
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.2)"
      : "rgba(0, 0, 0, 0.2)"
  }`,
  backgroundColor: `${
    theme.palette.background?.default || "#ffffff"
  } !important`,
  "&:hover": {
    backgroundColor: `${
      theme.palette.background?.toolbar || "#e0e0e0"
    } !important`,
  },
}));

const SidebarToggleButtonExpanded = styled(SidebarButton)(({ theme }) => ({
  right: -12,
}));

const SidebarToggleButtonCollapsed = styled(SidebarButton)(({ theme }) => ({
  left: -1,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
}));

const Toaster = () => {
  const { toastOpen, toastMessage, toastSeverity, toastOptions, setToastOpen } =
    useApp();
  const duration = toastOptions?.persist ? null : 6000;

  return (
    <Snackbar
      open={toastOpen}
      key={toastMessage}
      onClose={() => setToastOpen(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={duration}
    >
      <Alert
        onClose={() => setToastOpen(false)}
        variant="filled"
        severity={toastSeverity}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};

const ComponentBody = ({ children, routes, contentWidth }) => {
  const theme = useTheme();

  const { isSm, isXs } = useBreakpoints();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(
    JSON.parse(localStorage.getItem("isMainSidebarOpen")) ?? true
  );
  const [appContentWidth, setAppContentWidth] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!appContentWidth) {
      setAppContentWidth(
        isXs || isSm
          ? "100%"
          : drawerOpen && !mobileOpen
          ? `calc(100% - ${drawerWidth}px)`
          : "100%"
      );
    } else if (!drawerOpen || isXs || isSm) {
      setAppContentWidth("100%");
    } else {
      setAppContentWidth(`calc(100% - ${drawerWidth}px)`);
    }
  }, [isSm, isXs, drawerOpen, mobileOpen, appContentWidth]);

  const toggleOpen = () => {
    const newDrawerOpen = !drawerOpen;
    localStorage.setItem("isMainSidebarOpen", newDrawerOpen.toString());
    setDrawerOpen(newDrawerOpen);
    setAppContentWidth((prev) =>
      prev === `calc(100% - ${drawerWidth}px)`
        ? "100%"
        : `calc(100% - ${drawerWidth}px)`
    );
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyles styles={globalStyles(theme)} />
      <DrawerContainer className={!drawerOpen ? "isClosed" : ""}>
        {/* Mobile Sidebar */}
        <Box
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth, overflow: "visible" } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Box>

        {/* Desktop Sidebar */}
        <Box
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          {drawerOpen ? (
            <SidebarToggleButtonExpanded onClick={toggleOpen}>
              <ChevronLeft />
            </SidebarToggleButtonExpanded>
          ) : (
            <SidebarToggleButtonCollapsed onClick={toggleOpen}>
              <ChevronRight />
            </SidebarToggleButtonCollapsed>
          )}
          <Sidebar
            routes={routes}
            drawerOpen={drawerOpen}
            PaperProps={{
              style: {
                width: drawerWidth,
                overflow: "visible",
                background: "none",
              },
            }}
          />
        </Box>
      </DrawerContainer>
      <AppContent sx={{ maxWidth: appContentWidth }}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent sx={{ p: contentWidth }}>{children}</MainContent>
        <Footer />
        <Toaster />
      </AppContent>
    </Root>
  );
};

export const Dashboard = ({ children, routes, layoutOverride = null }) => {
  const { isUpLg, isUpSm } = useBreakpoints();
  const contentWidth =
    layoutOverride === "max" ? 0 : isUpLg ? 12 : isUpSm ? 8 : 5;
  return (
    <ComponentBody routes={routes} contentWidth={contentWidth}>
      {children}
    </ComponentBody>
  );
};

export const DashboardMaxContent = ({ children, routes }) => {
  return (
    <ComponentBody routes={routes} contentWidth={0}>
      {children}
    </ComponentBody>
  );
};
