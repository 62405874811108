import { darken, lighten } from "@mui/material";

const secondarySource = "#2F9BA9";

export const customSecondary = {
  50: darken(secondarySource, 0.25),
  100: darken(secondarySource, 0.2),
  200: darken(secondarySource, 0.15),
  300: darken(secondarySource, 0.1),
  400: darken(secondarySource, 0.05),
  500: secondarySource,
  600: lighten(secondarySource, 0.05),
  700: lighten(secondarySource, 0.1),
  800: lighten(secondarySource, 0.15),
  900: lighten(secondarySource, 0.2),
};

const highlightSource = "#3e485b";

export const customHighlight = {
  50: darken(highlightSource, 0.25),
  100: darken(highlightSource, 0.2),
  200: darken(highlightSource, 0.15),
  300: darken(highlightSource, 0.1),
  400: darken(highlightSource, 0.05),
  500: highlightSource,
  600: lighten(highlightSource, 0.05),
  700: lighten(highlightSource, 0.1),
  800: lighten(highlightSource, 0.15),
  900: lighten(highlightSource, 0.2),
};

const darkSource = "#1D2228";

export const customDark = {
  50: darken(darkSource, 0.25),
  100: darken(darkSource, 0.2),
  200: darken(darkSource, 0.15),
  300: darken(darkSource, 0.1),
  400: darken(darkSource, 0.05),
  500: darkSource,
  600: lighten(darkSource, 0.05),
  700: lighten(darkSource, 0.1),
  800: lighten(darkSource, 0.15),
  900: lighten(darkSource, 0.2),
};

const greySource = "#E1E2E2";

export const customGrey = {
  50: darken(greySource, 0.25),
  100: darken(greySource, 0.2),
  200: darken(greySource, 0.15),
  300: darken(greySource, 0.1),
  400: darken(greySource, 0.05),
  500: greySource,
  600: lighten(greySource, 0.05),
  700: lighten(greySource, 0.1),
  800: lighten(greySource, 0.15),
  900: lighten(greySource, 0.2),
};
