import { createTheme as createMuiTheme } from "@mui/material/styles";
import typography from "./typography";
import components from "./components";
import breakpoints from "./breakpoints";
import shadows from "./shadows";
import { darken } from "@mui/material";
import {
  customDark,
  customGrey,
  customHighlight,
  customSecondary,
} from "./variants";
import { green } from "@mui/material/colors";

const createTheme = () => {
  return createMuiTheme({
    spacing: 4,
    breakpoints: breakpoints,
    components: components,
    typography: typography,
    shadows: shadows,
    palette: {
      mode: "light",
      primary: {
        main: customHighlight[700],
        contrastText: "#FFF",
      },
      secondary: {
        main: customSecondary[500],
        contrastText: "#FFF",
      },
      background: {
        default: "#F7F9FC",
        toolbar: darken("#F7F9FC", 0.05),
        toolbar2: darken("#F7F9FC", 0.02),
        paper: "#FFF",
      },
    },
    header: {
      color: customGrey[200],
      background: customHighlight[800],
      search: {
        color: customGrey[100],
      },
      indicator: {
        background: customHighlight[700],
      },
    },
    sidebar: {
      color: customDark[50],
      background: "#FFF",
      header: {
        color: "#FFF",
        background: customHighlight[800],
        brand: {
          color: "#FFFFFF",
        },
      },
      footer: {
        color: customDark[800],
        background: "#F7F7F7",
        online: {
          background: green[500],
        },
      },
    },
    footer: {
      color: customDark[800],
      background: "#FFF",
    },
    scrollbar: {
      thumb: darken(customGrey[300], 0.05),
      track: customGrey[500],
    },
    severity: {
      success: "",
      warning: "",
      info: "",
      error: "",
    },
  });
};

export default createTheme;
