import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  InputAdornment,
  Popper,
  TextField,
  ButtonGroup,
  Button,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemButton,
  List,
  Typography,
  Grid,
  Divider,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fuse from "fuse.js";
import useDebounce from "../../../../hooks/useDebounce";
import { SEARCH_CONFIG } from "../../constants";

const SearchResults = memo(
  ({ anchorEl, isOpen, onClose, onSelect, searchResults, selectedLayer }) => {
    const layerConfig = SEARCH_CONFIG[selectedLayer.id];
    if (!layerConfig) return null;

    return (
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal={false} // Ensuring disablePortal is false
        sx={{ zIndex: 1301 }}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ width: 500, maxHeight: 470, overflowY: "auto" }}>
              <ClickAwayListener onClickAway={onClose}>
                <List dense component="nav" aria-label="search results">
                  {searchResults.slice(0, 50).map(({ item }, i) => (
                    <Fragment key={item.key || i}>
                      <ListItemButton
                        sx={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                        onClick={() => onSelect(item)}
                      >
                        <Typography variant="subtitle1">
                          {item[layerConfig.displayFields[0].field] || "N/A"}
                        </Typography>
                        <Grid container>
                          {layerConfig.displayFields.slice(1).map((field) => (
                            <Grid item xs={4} key={field.field}>
                              <Typography variant="caption">
                                {field.label}
                              </Typography>
                              <Typography variant="body1">
                                {item[field.field] || "N/A"}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </ListItemButton>
                      <Divider />
                    </Fragment>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }
);

const Search = ({ onSelect, sources }) => {
  const searchRef = useRef(null);
  const layerSelectButtonRef = useRef(null);

  // Combine sources and SEARCH_CONFIG to create availableLayers
  const availableLayers = useMemo(() => {
    return sources
      .filter(({ id }) => SEARCH_CONFIG[id])
      .map(({ id, data }) => {
        const config = SEARCH_CONFIG[id];
        return {
          id: id,
          label: config.label,
          data: data.features.map((item) => item.properties),
          searchableFields: config.searchableFields,
          searchPlaceholder: config.searchPlaceholder,
          displayFields: config.displayFields,
        };
      });
  }, [sources]);

  const [selectedLayer, setSelectedLayer] = useState(null);

  // Set initial selected layer
  useEffect(() => {
    if (availableLayers.length && !selectedLayer) {
      setSelectedLayer(availableLayers[0]);
    }
  }, [availableLayers, selectedLayer]);

  const [searchInputValue, setSearchInputValue] = useState("");
  const debouncedSearchValue = useDebounce(searchInputValue, 200);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchResultsOpen, setIsSearchResultsOpen] = useState(false);
  const [isLayerMenuOpen, setIsLayerMenuOpen] = useState(false);

  // Initialize Fuse.js searcher based on selectedLayer
  const fuseSearcher = useMemo(() => {
    if (selectedLayer) {
      return new Fuse(selectedLayer.data, {
        keys: selectedLayer.searchableFields,
        threshold: 0.3,
      });
    }
    return null;
  }, [selectedLayer]);

  // Update search results based on debounced search input
  useEffect(() => {
    if (fuseSearcher && debouncedSearchValue) {
      setSearchResults(fuseSearcher.search(debouncedSearchValue));
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchValue, fuseSearcher]);

  // Control visibility of search results
  useEffect(() => {
    setIsSearchResultsOpen(searchResults.length > 0);
  }, [searchResults]);

  // Handle layer selection by id
  const handleLayerSelect = (id) => {
    const newSelectedLayer = availableLayers.find((layer) => layer.id === id);
    if (newSelectedLayer) {
      setSelectedLayer(newSelectedLayer);
      setIsLayerMenuOpen(false);
      setSearchResults([]);
    }
  };

  const toggleLayerMenu = () => {
    setIsLayerMenuOpen((prevOpen) => !prevOpen);
  };

  const closeLayerMenu = (event) => {
    if (layerSelectButtonRef.current?.contains(event.target)) {
      return;
    }
    setIsLayerMenuOpen(false);
  };

  const closeSearchResults = useCallback((event) => {
    if (searchRef.current?.contains(event.target)) {
      return;
    }
    setIsSearchResultsOpen(false);
  }, []);

  if (!selectedLayer) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      {availableLayers.length > 1 && (
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={layerSelectButtonRef}
          aria-label="layer selection"
        >
          <Button
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              height: "36px",
            }}
            color="primary"
            variant="outlined"
            aria-controls={isLayerMenuOpen ? "layer-menu" : undefined}
            aria-expanded={isLayerMenuOpen ? "true" : undefined}
            aria-label="select layer"
            aria-haspopup="menu"
            onClick={toggleLayerMenu}
            size="small"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      )}

      <Popper
        open={isLayerMenuOpen}
        anchorEl={layerSelectButtonRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={{ zIndex: 1301 }}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeLayerMenu}>
                <MenuList id="layer-menu">
                  {availableLayers.map((layer) => (
                    <MenuItem
                      key={layer.id}
                      selected={layer.id === selectedLayer.id}
                      onClick={() => handleLayerSelect(layer.id)}
                    >
                      {layer.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <TextField
        ref={searchRef}
        autoComplete="off"
        label={selectedLayer.searchPlaceholder || "Search"}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              borderTopLeftRadius: availableLayers.length > 1 ? 0 : undefined,
              borderBottomLeftRadius:
                availableLayers.length > 1 ? 0 : undefined,
            },
          },
        }}
        onChange={(e) => setSearchInputValue(e.target.value)}
        onFocus={() => {
          if (searchInputValue) {
            setIsSearchResultsOpen(true);
          }
        }}
        placeholder={selectedLayer.searchPlaceholder || "Search"}
        sx={{ width: "100%", minWidth: "245px", ml: "-1px" }}
        type="search"
        value={searchInputValue}
        variant="outlined"
        size="small"
      />

      <SearchResults
        anchorEl={searchRef.current}
        onClose={closeSearchResults}
        onSelect={onSelect}
        isOpen={isSearchResultsOpen}
        searchResults={searchResults}
        selectedLayer={selectedLayer}
      />
    </Box>
  );
};

export default memo(Search);
