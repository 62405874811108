import React, { useMemo, useState, memo } from "react";
import { styled } from "@mui/material/styles";
import { isNullOrUndef } from "chart.js/helpers";
import { formatBooleanTrueFalse } from "../../../utils";
import { Pagination, Divider, Typography } from "@mui/material";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { titleize } from "inflected";

const PopupWrap = styled("div")(({ height, width }) => ({
  height,
  overflowY: "scroll",
  width,
}));

const PopupTable = styled("table")({
  borderRadius: "5px",
  borderCollapse: "collapse",
  border: "1px solid #ccc",
  width: "100%",
});

const PopupRow = styled("tr")({
  borderRadius: "5px",
  "&:nth-of-type(even)": {
    backgroundColor: "#eee",
  },
});

const PopupCell = styled("td")({
  padding: "3px 6px",
  margin: 0,
});

function getUniqueFeatures(array, comparatorProperty1, comparatorProperty2) {
  const existingFeatureKeys = new Set();
  return array.filter((el) => {
    const key = `${el[comparatorProperty1]}-${el.layer[comparatorProperty2]}`;
    if (existingFeatureKeys.has(key)) {
      return false;
    } else {
      existingFeatureKeys.add(key);
      return true;
    }
  });
}

const Popup = ({
  features,
  layers,
  currentUser,
  height = "200px",
  maxWidth = "380px",
  size = "medium",
}) => {
  const { isXs, isSm } = useBreakpoints();
  const [page, setPage] = useState(1);

  const uniqueFeatures = useMemo(
    () => getUniqueFeatures(features, "id", "id"),
    [features]
  );

  const feature = useMemo(() => {
    return uniqueFeatures[page - 1] || null;
  }, [uniqueFeatures, page]);

  const { excludeFields, excludePatterns } = useMemo(() => {
    if (!feature) return { excludeFields: [], excludePatterns: [] };

    const layer = layers.find((layer) => layer?.id === feature?.layer?.id);
    if (!layer) return { excludeFields: [], excludePatterns: [] };

    const popupConfig = layer?.lreProperties?.popup || {};

    const baseExcludedFields = popupConfig.excludeFields || [];
    const baseExcludedPatterns = popupConfig.excludePatterns || [];

    const adminExcludedFields =
      currentUser?.isAdmin || currentUser?.isDeveloper
        ? []
        : popupConfig.adminOnlyFields || [];

    return {
      excludeFields: [...baseExcludedFields, ...adminExcludedFields],
      excludePatterns: baseExcludedPatterns,
    };
  }, [feature, layers, currentUser]);

  const titleField = useMemo(() => {
    if (!feature) return null;

    const layer = layers.find((layer) => layer?.id === feature?.layer?.id);
    if (!layer) return null;

    const title = layer?.lreProperties?.popup?.titleField;
    const titleValue = title ? feature?.properties[title] : null;

    if (title && titleValue) {
      return (
        <>
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            {feature?.layer?.source === "north-plains-gcd-wells"
              ? "NPGCD Wells"
              : titleize(feature?.layer?.source)}
            :
          </Typography>
          <Divider sx={{ my: 1, mx: 2 }} />
          <Typography sx={{ mb: 2 }} variant="h6">
            {titleValue}
          </Typography>
        </>
      );
    }

    return (
      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        {titleize(feature?.layer?.source)}
      </Typography>
    );
  }, [feature, layers]);

  const popupData = useMemo(() => {
    if (!feature) return [];

    return Object.entries(feature?.properties).reduce((acc, [key, value]) => {
      // Check if the key matches any of the patterns in excludePatterns
      const matchesPattern = excludePatterns.some((pattern) =>
        key.includes(pattern)
      );

      // Exclude fields based on exact match or pattern match
      if (
        !excludeFields.includes(key) &&
        !matchesPattern &&
        value !== "" &&
        !isNullOrUndef(value)
      ) {
        acc.push([key, value]);
      }
      return acc;
    }, []);
  }, [feature, excludeFields, excludePatterns]);

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  if (!feature) return null;

  return (
    <>
      <PopupWrap
        height={isXs ? "120px" : height}
        width={isSm ? "205px" : maxWidth}
      >
        {titleField}
        <PopupTable>
          <tbody>
            {popupData.map(([key, value]) => (
              <PopupRow key={key}>
                <PopupCell>
                  <strong>{titleize(key)}</strong>
                </PopupCell>
                <PopupCell>{formatBooleanTrueFalse(value)}</PopupCell>
              </PopupRow>
            ))}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        count={uniqueFeatures.length}
        size={isSm ? "small" : size}
        siblingCount={isSm ? 0 : 1}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default memo(Popup);
