export const CRUD_LOOKUP_TABLES = [];

export const THEME = {
  MAIN_SIDEBAR_WIDTH: 258,
  DATE_FORMAT_LONG: "MMMM do yyyy, h:mma",
};

export const ROUTES = {
  HOME: "/",
  USER_LOGOUT: "/auth/logout",
  SPLASH: "/login",
};
