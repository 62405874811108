import React from "react";
import { createRoot } from "react-dom/client";
import { Tooltip } from "@mui/material";

class DragCircleControl {
  constructor(draw) {
    this.draw = draw;
    this._container = null;
    this._root = null;
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.draw.changeMode("draw_circle");
  }

  onAdd() {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const icon = (
      <button
        type="button"
        className="material-icons"
        style={{ verticalAlign: "middle", cursor: "pointer" }}
      >
        trip_origin
      </button>
    );

    const tooltipContent = (
      <Tooltip title="Circle Tool" arrow placement="left">
        <span onClick={this.handleClick}>{icon}</span>
      </Tooltip>
    );

    this._root = createRoot(this._container);
    this._root.render(tooltipContent);

    return this._container;
  }

  onRemove() {
    if (this._root) {
      this._root.unmount();
    }
    if (this._container && this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
    }
    this._container = null;
    this._root = null;
  }
}

export default DragCircleControl;
