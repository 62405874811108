export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-97.67667, 41.61364];
export const DEFAULT_MAP_ZOOM = 9.2;
export const DEFAULT_MAP_BASEMAP = BASEMAP_STYLES[0].url;

export const INIT_MAP_CONFIG = {
  style: DEFAULT_MAP_BASEMAP,
  center: DEFAULT_MAP_CENTER,
  zoom: DEFAULT_MAP_ZOOM,
  preserveDrawingBuffer: true,
  attributionControl: true,
};

export const WELLS_LAYER_ID = "map-wells-circle";
export const WELLS_LABELS_LAYER_ID = "map-wells-symbol";
export const INIT_FILTER_VALUES = {
  counties: {
    label: "County",
    name: "counties",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "county",
    options: [],
    type: "multi-select",
    value: [],
  },
  depthRanges: {
    label: "Depth",
    name: "depthRanges",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "depth_range_desc",
    options: [],
    type: "multi-select",
    value: [],
  },
  nrds: {
    label: "NRD",
    name: "nrds",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "nrd_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  sources: {
    label: "Source",
    name: "sources",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "source",
    options: [],
    type: "multi-select",
    value: [],
  },
  status: {
    label: "Status",
    name: "status",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "status",
    options: [],
    type: "multi-select",
    value: [],
  },
  uses: {
    label: "Use",
    name: "uses",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "use",
    options: [],
    type: "multi-select",
    value: [],
  },
  wellCompletion: {
    label: "Completion",
    name: "wellCompletion",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "completion_desc",
    options: [],
    type: "multi-select",
    value: [],
  },
  isHighcap: {
    label: "High Capacity?",
    name: "isHighcap",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_highcap",
    type: "boolean",
    value: false,
  },
  isConfined: {
    label: "Confined?",
    name: "isConfined",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_confined",
    type: "boolean",
    value: false,
  },
  isUnconfined: {
    label: "Unconfined?",
    name: "isUnconfined",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_unconfined",
    type: "boolean",
    value: false,
  },
};

const layerId = "map-wells-circle";
export const INIT_STYLE_VALUES = {
  default: {
    id: "default",
    layerId,
    name: "Default",
    paint: {
      "circle-color": "#1e8dd2",
    },
  },
  counties: {
    id: "counties",
    layerId,
    layerFieldName: "county",
    name: "County",
    paint: {
      "circle-color": [
        "match",
        ["get", "county"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  depthRanges: {
    id: "depthRanges",
    layerId,
    layerFieldName: "depth_range_desc",
    name: "Depth",
    paint: {
      "circle-color": [
        "match",
        ["get", "depth_range_desc"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  nrds: {
    id: "nrds",
    layerId,
    layerFieldName: "nrd_name",
    name: "NRD",
    paint: {
      "circle-color": [
        "match",
        ["get", "nrd_name"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  sources: {
    id: "sources",
    defaultLoad: true,
    layerId,
    layerFieldName: "source",
    name: "Source",
    paint: {
      "circle-color": [
        "match",
        ["get", "source"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  status: {
    id: "status",
    layerId,
    layerFieldName: "status",
    name: "Status",
    paint: {
      "circle-color": [
        "match",
        ["get", "status"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  uses: {
    id: "uses",
    layerId,
    layerFieldName: "use",
    name: "Use",
    paint: {
      "circle-color": [
        "match",
        ["get", "use"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  wellCompletion: {
    id: "wellCompletion",
    layerId,
    layerFieldName: "completion_desc",
    name: "Completion",
    paint: {
      "circle-color": [
        "match",
        ["get", "completion_desc"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
};

export const SEARCH_CONFIG = {
  "map-wells": {
    id: "map-wells",
    label: "Wells",
    key: "well_ndx",
    searchableFields: ["well_id", "nrd_name", "owner_name"],
    searchPlaceholder: "Search Wells",
    displayFields: [
      { field: "well_id", label: "Well ID" },
      { field: "nrd_name", label: "NRD" },
      { field: "owner_name", label: "Owner" },
      { field: "county", label: "County" },
      { field: "status", label: "Status" },
      { field: "source", label: "Source" },
      { field: "use", label: "Use" },
      { field: "well_depth", label: "Depth" },
    ],
  },
  "map-wells2": {
    id: "map-wells2",
    label: "Wells2",
    key: "well_ndx",
    searchableFields: ["well_id", "nrd_name", "owner_name"],
    searchPlaceholder: "Search Wells2",
    displayFields: [{ field: "well_elev_ft", label: "ELEV" }],
  },
};

export const UNIQUE_LEGEND_GROUPS = ["North Plains Wells"];
export const DEFAULT_EXPANDED_LAYERS = ["Wells"];
export const DEFAULT_EXPANDED_LAYER_GROUPS = ["North Plains Wells"];
