import React, { memo, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem as MuiListItem,
  ListItemText,
  Slider,
  Typography,
  Accordion,
  AccordionSummary,
  ListItemIcon,
  ButtonBase,
} from "@mui/material";

import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import { customSecondary } from "../../../../theme/variants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InsertLink } from "@mui/icons-material";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import {
  DEFAULT_EXPANDED_LAYER_GROUPS,
  DEFAULT_EXPANDED_LAYERS,
} from "../../constants";

const SidebarSection = styled(Typography)(({ theme }) => ({
  color: customSecondary[500],
  padding: `${theme.spacing(1)}px ${theme.spacing(5)}px ${theme.spacing(0)}`,
  opacity: 0.9,
  fontWeight: theme.typography.fontWeightBold,
  display: "block",
}));

const ListItem = styled(MuiListItem)({
  paddingTop: 0,
  paddingBottom: 0,
});

const getLegendOptions = (layer, activeStyle) => {
  const colorProperty = `${layer?.type}-color`;
  const color = layer?.paint?.[colorProperty];

  if (!Array.isArray(color)) {
    return [{ color, text: layer.name }];
  }

  const colorsExpression = [...color];
  let legendOptions;

  if (colorsExpression[0] === "case") {
    colorsExpression.splice(0, 1);
    legendOptions = colorsExpression
      .map((value, index) => {
        if (index < colorsExpression.length - 1 && index % 2 === 0) {
          return {
            color: colorsExpression[index + 1],
            text: Array.isArray(value)
              ? (index > 1 ? colorsExpression[index - 2][2] : 0) +
                (index >= colorsExpression.length - 3
                  ? "+"
                  : " - " + value[2]) +
                (layer?.units || activeStyle?.units || "")
              : value,
          };
        }
        return null;
      })
      .filter((d) => d !== null);
  } else {
    colorsExpression.splice(0, 2);

    legendOptions = colorsExpression
      .map((value, index) => {
        if (index < colorsExpression.length - 1 && index % 2 === 0) {
          return {
            color: colorsExpression[index + 1],
            text: Array.isArray(value)
              ? value?.join(", ")
              : value + (layer?.units || ""),
          };
        }
        return null;
      })
      .filter((d) => d !== null);
  }

  return legendOptions;
};

const LegendSymbol = ({ color }) => (
  <Box
    bgcolor={color}
    borderRadius="50%"
    height={12}
    width={12}
    sx={{ minWidth: "12px" }}
  />
);

const LayerLegendIcon = ({ open }) =>
  open ? <ExpandMore /> : <ChevronRight />;

const handleSliderChange = (event, newValue, item, onOpacityChange) => {
  const itemId = item.id;

  onOpacityChange({ id: itemId, opacity: newValue / 100 });
};

const LayerSlider = ({ item, onOpacityChange }) => {
  const layerFillOpacity =
    item?.type === "fill" &&
    (item?.paint["fill-opacity"] === 0 || item?.paint["fill-opacity"])
      ? item?.paint["fill-opacity"]
      : null;

  return layerFillOpacity || layerFillOpacity === 0 ? (
    <Box mb={-2}>
      <SidebarSection id="fill-opacity">Fill Opacity</SidebarSection>
      <Slider
        color="secondary"
        valueLabelDisplay="auto"
        value={
          +(
            (typeof item.paint["fill-opacity"] === "number"
              ? item.paint["fill-opacity"]
              : item.paint["fill-opacity"][
                  item.paint["fill-opacity"].length - 1
                ]) * 100
          ).toFixed(0)
        }
        onChange={(event, newValue) =>
          handleSliderChange(event, newValue, item, onOpacityChange)
        }
        aria-labelledby="continuous-slider"
      />
    </Box>
  ) : null;
};

const LayerLegend = ({ item, open, onOpacityChange, activeStyle }) => {
  const { isSm } = useBreakpoints();
  if (!open) return null;
  const legendItems = getLegendOptions(item, activeStyle);
  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap="4px"
      mb={2}
      mx={isSm ? "18px" : 11}
    >
      {legendItems.map(({ color, text }) => (
        <Box
          className="print-legend"
          key={text}
          display="flex"
          alignItems="center"
          columnGap="8px"
        >
          <LegendSymbol color={color} />
          <Typography color="textSecondary" variant="body2">
            {text}
          </Typography>
        </Box>
      ))}
      <LayerSlider item={item} onOpacityChange={onOpacityChange} />
    </Box>
  );
};

//TODO refactor this, just waiting to have more layers
//DEBOUNCE OPACITY
const LayersControl = ({
  items,
  onLayerChange,
  onOpacityChange,
  activeStyle,
  uniqueLegendGroups,
}) => {
  const [expandedItems, setExpandedItems] = useState(DEFAULT_EXPANDED_LAYERS);

  const uniqueItems = useMemo(() => {
    const uniqueItemIds = [
      ...new Set(
        items.map((item) => item?.lreProperties?.layerGroup || item.id)
      ),
    ];
    return uniqueItemIds
      .reduce((acc, curr) => {
        const match = items.find((item) => {
          const id = item?.lreProperties?.layerGroup || item.id;
          return id === curr;
        });
        acc.push(match);
        return acc;
      }, [])
      ?.sort((a, b) => ((a.legendOrder ?? 0) > (b.legendOrder ?? 0) ? -1 : 1));
  }, [items]);

  const handleVisibilityChange = (item) => {
    const itemId =
      item?.lreProperties?.labelGroup ||
      (item?.lreProperties?.labelGroup && item.type === "symbol")
        ? item?.lreProperties?.labelGroup
        : item?.lreProperties?.layerGroup || item.id;

    onLayerChange({ id: itemId, visible: item?.layout?.visibility === "none" });
  };

  const handleExpandItem = (value) => {
    setExpandedItems((prevState) => {
      const newValues = [...prevState];
      const existingIndex = newValues.indexOf(value);
      if (existingIndex > -1) {
        newValues.splice(existingIndex, 1);
      } else {
        newValues.push(value);
      }
      return newValues;
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <List dense>
        {uniqueItems?.length === 0 && (
          <Box textAlign="center">
            <Typography variant="body1">No layers found</Typography>
          </Box>
        )}
        {uniqueLegendGroups.map((legendGroup) => {
          return (
            <Accordion
              key={legendGroup}
              defaultExpanded={DEFAULT_EXPANDED_LAYER_GROUPS.includes(
                legendGroup
              )}
            >
              <AccordionSummary
                sx={{ display: "flex", flexDirection: "row-reverse" }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{legendGroup}</Typography>
              </AccordionSummary>
              {uniqueItems
                .filter(
                  (uniqueItem) =>
                    uniqueItem.lreProperties?.legendGroup === legendGroup
                )
                .map((item) => {
                  const open = expandedItems.includes(item?.name);
                  const layerVisible = item?.layout?.visibility === "visible";
                  return (
                    <Box key={item?.name} id="layerCheck">
                      {!item?.lreProperties?.popoutLink ? (
                        <ListItem
                          onClick={() => {
                            handleVisibilityChange(item);
                            (!layerVisible &&
                              !open &&
                              handleExpandItem(item?.name)) ||
                              (layerVisible &&
                                open &&
                                handleExpandItem(item?.name));
                          }}
                        >
                          <Checkbox
                            edge="start"
                            checked={layerVisible}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": "test" }}
                          />
                          <ListItemText
                            primary={item?.name}
                            primaryTypographyProps={{
                              color: layerVisible
                                ? "textPrimary"
                                : "textSecondary",
                            }}
                          />
                          {!item?.lreProperties?.excludeLayerCollapse && (
                            <Box mr={1}>
                              <IconButton
                                edge="end"
                                aria-label="expand"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleExpandItem(item?.name);
                                }}
                              >
                                <LayerLegendIcon open={open} />
                              </IconButton>
                            </Box>
                          )}
                        </ListItem>
                      ) : (
                        <ButtonBase
                          component="a"
                          href={item?.lreProperties?.popoutLink}
                        >
                          <ListItem
                            sx={{ cursor: "pointer" }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ListItemIcon>
                              <InsertLink />
                            </ListItemIcon>
                            <ListItemText
                              primary={item?.name}
                              primaryTypographyProps={{
                                color: layerVisible
                                  ? "textPrimary"
                                  : "textSecondary",
                              }}
                            />
                          </ListItem>
                        </ButtonBase>
                      )}
                      {!item?.lreProperties?.excludeLayerCollapse && (
                        <LayerLegend
                          open={open}
                          item={item}
                          onOpacityChange={onOpacityChange}
                          handleVisibilityChange={handleVisibilityChange}
                          items={items}
                          activeStyle={activeStyle}
                        />
                      )}
                    </Box>
                  );
                })}
            </Accordion>
          );
        })}
      </List>
    </Box>
  );
};

export default memo(LayersControl);
