import React from "react";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Button,
  Container,
  Grid,
  Toolbar,
  Tooltip,
  Link,
} from "@mui/material";

import { ROUTES } from "../../../constants";
import useBreakpoints from "../../../hooks/useBreakpoints";

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const BrandIcon = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: "flex",
  alignItems: "center",
}));

function AppBarComponent() {
  const { loginWithRedirect } = useAuth0();
  const { isXs } = useBreakpoints(); // Using the custom hook to detect the breakpoint

  return (
    <AppBar position="absolute" color="transparent" elevation={0}>
      <Toolbar>
        <Container>
          <Grid container alignItems="center">
            <Grid item>
              <Link
                href="https://lrewater.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Tooltip title="Built by LRE Water">
                  <BrandIcon
                    src={`/static/img/lrewater-logo-simple.svg`}
                    width={isXs ? "125" : "150"}
                    height={isXs ? "40" : "48"}
                    alt="LREWater.com"
                  />
                </Tooltip>
              </Link>
            </Grid>

            <Grid item xs />
            <Grid item>
              <StyledButton
                color="primary"
                variant="contained"
                onClick={() =>
                  loginWithRedirect({
                    appState: { returnTo: ROUTES.PAGE_DASHBOARD },
                  })
                }
              >
                {isXs ? "Login" : "Log in to Dashboard"}
              </StyledButton>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;
