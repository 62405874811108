import React from "react";
import { styled } from "@mui/material/styles";
import { Container, Grid, Typography, Link, Box } from "@mui/material";
import { BackgroundVideo } from "../../../components/BackgroundVideo";

const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: "7rem",
  position: "relative",
  textAlign: "center",
  overflow: "hidden",
  height: "470px",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.text.secondary
      : theme.palette.text.primary,
}));

const Content = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: `${theme.spacing(6)} 0`,
  lineHeight: "150%",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
  margin: `${theme.spacing(2)} 0`,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.text.secondary
      : theme.palette.text.secondary,
}));

const BrandLogo = styled("img")({
  verticalAlign: "middle",
  maxHeight: "100%",
  maxWidth: "100%",
  width: "209px",
});

function Introduction() {
  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={10} sm={9} md={8} lg={7}>
            <BackgroundVideo mp4={"/static/video/water.mp4"} />
            <Content>
              <Link
                href="https://northplainsgcd.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <BrandLogo
                  src="/static/img/northplainsgcd-white-logo.jpg"
                  alt="North Plains GCD Logo"
                />
              </Link>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle>
                    Enter and analyze data. <br />
                    Access and visualize wells & properties. <br />
                    View reports & analytics. <br />
                  </Subtitle>
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Introduction;
