import { customHighlight } from "./variants";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";

const components = {
  //old overrides
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
      },
    },
  },
  MuiMobileStepper: {
    styleOverrides: {
      root: {
        background: "none",
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      shrink: {
        // fontSize: "16px",
        color: "black",
      },
      root: {
        color: "black",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: "rgba(0,0,0,1)",
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: "black",
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
    defaultProps: {
      titleTypographyProps: { variant: "h6" },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "0.65rem",
      },
    },
    defaultProps: {
      arrow: true,
      placement: "top",
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      expandIconWrapper: {
        marginLeft: "12px",
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      "::-moz-selection": {
        color: "white",
        background: customHighlight[500],
      },
      "::selection": {
        color: "white",
        background: customHighlight[500],
      },
      "*::-webkit-scrollbar": {
        width: "0.8em",
      },
      "*::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(178,178,178)",
        borderRadius: "8px",
      },
    },
  },
  // Add other components if necessary
  //old overrides
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiBreadcrumbs: {
    defaultProps: {
      separator: <NavigateNextIcon fontSize="small" />,
    },
  },
};

export default components;
