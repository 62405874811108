import React from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  Button,
  IconButton,
  Toolbar,
  Grid,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import UserDropdown from "./UserDropdown";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background: theme.header.background,
  color: theme.header.color,
}));

const AppBarComponent = ({ onDrawerToggle }) => (
  <AppBar position="sticky" elevation={0} width="100%">
    <Toolbar>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={onDrawerToggle}
            size="large"
            sx={{
              svg: {
                width: 22,
                height: 22,
                display: { md: "none", xs: "block" },
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Button
            sx={{ marginRight: 1 }}
            variant="contained"
            component="a"
            href="https://northplainsgcd.org"
          >
            NPGCD Website
          </Button>

          <UserDropdown />
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

export default AppBarComponent;
