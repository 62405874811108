import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Container,
  Grid,
  Typography as MuiTypography,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { useAuth0 } from "@auth0/auth0-react";

const Wrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  paddingTop: theme.spacing(16),
  paddingBottom: theme.spacing(16),
}));

const Subtitle = styled(MuiTypography)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
  opacity: 0.75,
}));

function JoinUs() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <MuiTypography variant="h2" gutterBottom>
              Access the Platform
            </MuiTypography>
            <Subtitle variant="h5" gutterBottom>
              Log in with user profile:
            </Subtitle>
            <Box mb={4} />

            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                loginWithRedirect({
                  appState: { returnTo: ROUTES.PAGE_DASHBOARD },
                })
              }
            >
              View Dashboard
            </Button>
            <Box ml={4} display="inline-block" />
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                loginWithRedirect({
                  appState: { returnTo: ROUTES.PAGE_PRODUCTION },
                })
              }
            >
              Enter Production Data
            </Button>
            <Box mb={10} />
            <Subtitle variant="h5" gutterBottom>
              View public resources:
            </Subtitle>
            <Box mb={4} />
            <Button
              color="primary"
              variant="outlined"
              component={Link}
              to={ROUTES.PUBLIC_MAP}
            >
              Public Access
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default JoinUs;
