import React from "react";
import { CssBaseline, GlobalStyles, styled } from "@mui/material";

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      html: {
        height: "100%",
        overflowX: "hidden",
      },
      body: {
        height: "100%",
        overflowX: "hidden",
        background: theme.palette.background.default,
      },
      "#root": {
        height: "100%",
      },
      "*::-webkit-scrollbar": {
        width: "16px",
        height: "16px",
        backgroundColor: theme.scrollbar.track,
      },
      "*::-webkit-scrollbar-corner": {
        backgroundColor: theme.scrollbar.track,
      },
      "*::-webkit-scrollbar-thumb": {
        borderRadius: "0px",
        border: "none",
        backgroundColor: theme.scrollbar.thumb,
      },
    })}
  />
);

const Root = styled("div")({
  display: "flex",
  minHeight: "100vh",
});

const AppContent = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const FullscreenMap = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      {globalStyles}
      <AppContent>{children}</AppContent>
    </Root>
  );
};

export default FullscreenMap;
